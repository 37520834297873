// app/javascript/channels/product_image_channel.js
import consumer from "./consumer"

const createProductImageChannel = (productId, callback) => {
    return consumer.subscriptions.create({ channel: 'ProductImageChannel', product_id: productId }, {
        received(data) {
        callback(data);
        }
    });
};

export default createProductImageChannel;