  /**
 * アイコンをクリックすると、子要素のリストの表示を切り替える関数です。
 * また、アイコン自体のクラスも切り替えて、開いているか閉じているかの状態を示します。
 */
export function toggleChildrenTag(icon) {
  // アイコンの親要素から子要素のリストを取得
  const children = icon.parentNode.querySelector('ul');
  // 子要素が存在する場合のみ処理を実行
  if (children) {
    // 子要素のリストの表示・非表示を切り替える
    children.classList.toggle('hidden');
    // アイコンのクラスを切り替えて、状態を示す
    icon.classList.toggle('fa-square-plus');
    icon.classList.toggle('fa-square-minus');
  }
}